import React from 'react';

export default function MoreInfo() {
    return(
        <section className="features1 cid-svNPueQhfd" id="features2-7">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="card-wrapper">
                            <div className="card-box align-center">
                                <span className="mbr-iconfont mbrib-pin"></span>
                                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7"><strong>Only available in MBABANE</strong></h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="card-wrapper">
                            <div className="card-box align-center">
                                <span className="mbr-iconfont mbrib-calendar"></span>
                                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7"><strong>You can reserve a seat annually, monthly, weekly or daily</strong></h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="card-wrapper">
                            <div className="card-box align-center">
                                <span className="mbr-iconfont mbrib-chat"></span>
                                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7"><strong>Easy communication with driver at all times</strong></h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="card-wrapper">
                            <div className="card-box align-center">
                                <span className="mbr-iconfont mbrib-map-pin"></span>
                                <h4 className="card-title align-center mbr-black mbr-fonts-style display-7"><strong>You can view live location of vehicle fetching your kid</strong></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}