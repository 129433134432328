import axios from 'axios';

 

const apiClient = axios.create({

    baseURL: 'https://bpdteam.co.za',
    // baseURL: 'http://134.122.25.57',
    // baseURL: 'http://127.0.0.1:8001',

    withCredentials: true,

});

 

export default apiClient;