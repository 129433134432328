import React from 'react';

import Navbar from '../components/Navbar';
import SignupForm from '../components/SignupForm';


export default function SignUp() {

    return (
        <>
            <Navbar/>
            <SignupForm/>
        </>
    );
}