import React from 'react';

export default function Navbar() {
    return(
        <section className="menu menu2 cid-svexzF3tDm" id="menu2-0">

                <nav className="navbar navbar-dropdown navbar-fixed-top collapsed">
                    <div className="container">
                        <div className="navbar-brand">
                            <span className="navbar-logo">
                                <a href="/">
                                    <img src="assets/images/bpd-logo1-black-removebg-preview-240x242.png" alt="FetchMyKid" style={{height: '4.5rem'}}/>
                                </a>
                            </span>
                            <span className="navbar-caption-wrap"><a className="navbar-caption text-black display-7" href="/">FetchMyKid</a></span>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded={true} aria-label="Toggle navigation">
                            <div className="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true"><li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="index.html#features2-7">The Info</a></li>
                                <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="index.html#gallery5-6">The Transport</a></li>
                                <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="index.html#team1-5">The Team</a></li>
                                <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="index.html#form7-3">Get a Quote</a></li>
                                </ul>

                            <div className="navbar-buttons mbr-section-btn"><a className="btn btn-primary display-4" href="https://chatwith.io/s/fetchmykid" target="_blank" rel="noreferrer"><span className="socicon socicon-whatsapp mbr-iconfont mbr-iconfont-btn"></span>Chat on WhatsApp</a></div>
                        </div>
                    </div>
                </nav>
            </section>
    );
}