import React from 'react';

import Navbar from '../components/Navbar';
import BannerHome from '../components/Banner/home';
import MoreInfo from '../components/MoreInfo';
import Transport from '../components/Transport';
import Drivers from '../components/Drivers';
import Communication from '../components/Communication';


export default function Home() {

    return (
        <>
            <Navbar/>
            <BannerHome/>
            <MoreInfo/>
            <Transport/>
            <Drivers/>
            <Communication/>
        </>
    );
}