import React from "react";

export default function DriverCard(props) {

    return(
        <div className="card-wrap">
            <div className="image-wrap">
                <img src={props.image} height={350} alt=""/>
            </div>
            <div className="content-wrap">
                <h5 className="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5"><strong>{props.name}</strong></h5>
                <h6 className="mbr-role mbr-fonts-style align-center mb-3 display-4">
                    <strong>{props.role}</strong></h6>
                <p className="card-text mbr-fonts-style align-center display-7">{props.description}</p>
                <div className="social-row display-7">
                    {props.facebook !== ""?
                        <div className="soc-item">
                            <a href={props.facebook} target="_blank" rel="noreferrer">
                                <span className="mbr-iconfont socicon-facebook socicon"></span>
                            </a>
                        </div>
                        :
                        null
                    }
                    {props.instagram !== ""?
                        <div className="soc-item">
                            <a href={props.instagram} target="_blank" rel="noreferrer">
                                <span className="mbr-iconfont socicon-instagram socicon"></span>
                            </a>
                        </div>
                        :
                        null
                    }
                    {props.website !== ""?
                        <div className="soc-item">
                            <a href={props.website} target="_blank" rel="noreferrer">
                                <span className="mbr-iconfont mobi-mbri-globe-2 mobi-mbri"></span>
                            </a>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
}