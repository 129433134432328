import React from 'react';

export default function BannerNotFound() {
    return(
        <section className="header1 cid-svexBEkLH9 mbr-fullscreen mbr-parallax-background" id="header1-1">
            <div className="mbr-overlay" style={{opacity: '0.7', backgroundColor: 'rgb(68, 121, 217)'}}></div>

            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>Page Not Found</strong></h1>

                        <p className="mbr-text mbr-fonts-style display-7">The URL you have tried to access doesn't exist... Go to the <a href='http://fetchmykid.bpdteam.com'>Home Page</a></p>
                        <div className="mbr-section-btn mt-3"><a className="btn btn-primary display-4" href="https://chatwith.io/s/fetchmykid" target="_blank" rel="noreferrer"><span className="socicon socicon-whatsapp mbr-iconfont mbr-iconfont-btn"></span>Chat on WhatsApp</a></div>
                    </div>
                </div>
            </div>
        </section>
    );
}