import React from 'react';

import Navbar from '../components/Navbar';
import BannerNotFound from '../components/Banner/404';


export default function NotFound() {

    return (
        <>
            <Navbar/>
            <BannerNotFound/>
        </>
    );
}