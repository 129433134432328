import React from 'react';

export default function Transport() {
    return(
        <>
            <section className="gallery5 mbr-gallery cid-svNKty42jB" id="gallery5-6">
                <div className="container">
                    <div className="mbr-section-head">
                        <h3 className="mbr-section-title mbr-fonts-style align-center m-0 display-2"><strong>The Transport</strong></h3>
                        <h4 className="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-7">Our vehicles are comfortable, always clean and always serviced.</h4>
                    </div>
                    <div className="row mbr-gallery mt-4">
                        <div className="col-12 col-md-6 col-lg-4 item gallery-image">
                            <div className="item-wrapper" data-toggle="modal" data-target="#sMcMzsTVXG-modal">
                                <img className="w-100" src="assets/images/img-20210416-103240-506x228.jpg" alt="" data-slide-to="0" data-target="#lb-sMcMzsTVXG"/>
                                <div className ="icon-wrapper">
                                <span className ="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item gallery-image">
                            <div className="item-wrapper" data-toggle="modal" data-target="#sMcMzsTVXG-modal">
                                <img className="w-100" src="assets/images/img-20210416-103302-506x228.jpg" alt="" data-slide-to="1" data-target="#lb-sMcMzsTVXG"/>
                                <div className ="icon-wrapper">
                                <span className ="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item gallery-image">
                            <div className="item-wrapper" data-toggle="modal" data-target="#sMcMzsTVXG-modal">
                                <img className="w-100" src="assets/images/img-20210416-103331-506x228.jpg" alt="" data-slide-to="2" data-target="#lb-sMcMzsTVXG"/>
                                <div className ="icon-wrapper">
                                <span className ="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="modal mbr-slider" tabIndex={-1} role="dialog" aria-hidden={true} id="sMcMzsTVXG-modal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="carousel slide" id="lb-sMcMzsTVXG" data-interval="5000">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img className="d-block w-100" src="assets/images/img-20210416-103240-506x228.jpg" alt=""/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="assets/images/img-20210416-103302-506x228.jpg" alt=""/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="assets/images/img-20210416-103331-506x228.jpg" alt=""/>
                                            </div>

                                        </div>
                                        <ol className="carousel-indicators">
                                            <li data-slide-to="0" className="active" data-target="#lb-sMcMzsTVXG"></li>
                                            <li data-slide-to="1" data-target="#lb-sMcMzsTVXG"></li>
                                            <li data-slide-to="2" data-target="#lb-sMcMzsTVXG"></li>

                                        </ol>
                                        <a role="button" href="/" className="close" data-dismiss="modal" aria-label="Close">
                                        </a>
                                        <a className="carousel-control-prev carousel-control" role="button" data-slide="prev" href="#lb-sMcMzsTVXG">
                                            <span className="mobi-mbri mobi-mbri-arrow-prev" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next carousel-control" role="button" data-slide="next" href="#lb-sMcMzsTVXG">
                                            <span className="mobi-mbri mobi-mbri-arrow-next" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="gallery5 mbr-gallery cid-sMcqPwUhpN" id="gallery5-b">
                <div className="container">
                    <div className="row mbr-gallery mt-4">
                        <div className="col-12 col-md-6 col-lg-4 item gallery-image">
                            <div className="item-wrapper" data-toggle="modal" data-target="#sMcMzv7OWv-modal">
                                <img className="w-100" src="assets/images/1634564477212-copy-696x313.jpg" alt="" data-slide-to="0" data-target="#lb-sMcMzv7OWv"/>
                                <div className ="icon-wrapper">
                                <span className ="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item gallery-image">
                            <div className="item-wrapper" data-toggle="modal" data-target="#sMcMzv7OWv-modal">
                                <img className="w-100" src="assets/images/1634564477238-696x313.jpg" alt="" data-slide-to="1" data-target="#lb-sMcMzv7OWv"/>
                                <div className ="icon-wrapper">
                                <span className ="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item gallery-image">
                            <div className="item-wrapper" data-toggle="modal" data-target="#sMcMzv7OWv-modal">
                                <img className="w-100" src="assets/images/1634564477253-696x313.jpg" alt="" data-slide-to="2" data-target="#lb-sMcMzv7OWv"/>
                                <div className ="icon-wrapper">
                                <span className ="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="modal mbr-slider" tabIndex="-1" role="dialog" aria-hidden="true" id="sMcMzv7OWv-modal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="carousel slide" id="lb-sMcMzv7OWv" data-interval="5000">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img className="d-block w-100" src="assets/images/1634564477212-copy-696x313.jpg" alt=""/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="assets/images/1634564477238-696x313.jpg" alt=""/>
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block w-100" src="assets/images/1634564477253-696x313.jpg" alt=""/>
                                            </div>

                                        </div>
                                        <ol className="carousel-indicators">
                                            <li data-slide-to="0" className="active" data-target="#lb-sMcMzv7OWv"></li>
                                            <li data-slide-to="1" data-target="#lb-sMcMzv7OWv"></li>
                                            <li data-slide-to="2" data-target="#lb-sMcMzv7OWv"></li>

                                        </ol>
                                        <a role="button" href="/" className="close" data-dismiss="modal" aria-label="Close">
                                        </a>
                                        <a className="carousel-control-prev carousel-control" role="button" data-slide="prev" href="#lb-sMcMzv7OWv">
                                            <span className="mobi-mbri mobi-mbri-arrow-prev" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next carousel-control" role="button" data-slide="next" href="#lb-sMcMzv7OWv">
                                            <span className="mobi-mbri mobi-mbri-arrow-next" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}